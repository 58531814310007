import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BadgeTag, Tooltip } from 'components';
import { toShortFormat } from 'utils';
import { EMPTY_STATE } from 'consts';
import { Member } from 'generated/types';

import styles from './styles.module.scss';
import { getShownProjectRoles } from './getShownProjectRoles';

interface Props {
  member: Partial<Member>;
}

const PROJECT_MANAGER = 'Project Manager';
const DEFAULT_BADGE_COLOR = '#ebbc34';
const BLUE_BADGE_COLOR = '#335fb8';
const GRAY_BADGE_COLOR = '#EBEDEE';

export const MemberDataSection: FC<Props> = ({ member }) => {
  const { t } = useTranslation();

  const joinDate = () => (member?.join_date ? toShortFormat(member.join_date) : EMPTY_STATE);

  const exitDate = () => (member?.exit_date ? toShortFormat(member.exit_date) : EMPTY_STATE);

  const capacity = () => (member?.capacity ? `${member.capacity} ${t('viewTeamMember.hrs')}` : EMPTY_STATE);

  const seniority = () => (member?.member_seniority?.name ? member.member_seniority.name : EMPTY_STATE);
  const projectRoles = () => {
    if (!member?.member_role?.length) return EMPTY_STATE;

    const { shownRoles, hiddenRoles } = getShownProjectRoles(
      [...member?.member_role].sort((a) => (a.name === PROJECT_MANAGER ? -1 : 1)),
    );

    return (
      <>
        {shownRoles.map((name: string) => (
          <BadgeTag key={name} color={name === PROJECT_MANAGER ? BLUE_BADGE_COLOR : DEFAULT_BADGE_COLOR}>
            {name}
          </BadgeTag>
        ))}
        {!!hiddenRoles.length && (
          <Tooltip
            title={
              <div className={styles.hiddenRolesTooltip}>
                {hiddenRoles.map((name: string) => (
                  <BadgeTag key={name} color={DEFAULT_BADGE_COLOR}>
                    {name}
                  </BadgeTag>
                ))}
              </div>
            }
            alwaysShowTooltip
          >
            <BadgeTag color={GRAY_BADGE_COLOR}>
              {hiddenRoles.length} {t('viewTeamMember.more')}
            </BadgeTag>
          </Tooltip>
        )}
      </>
    );
  };

  const specialization = () => (member?.member_specialization?.name ? member.member_specialization.name : EMPTY_STATE);

  return (
    <div className="flex flex-1">
      <div className={styles.container}>
        <h3 className={styles.title}>{t('viewTeamMember.information')}</h3>

        <div className={styles.infoBox}>
          <div className="flex flex-column justify-between">
            <div className={styles.box}>
              {t('viewTeamMember.joiningDate').toUpperCase()}
              <span className={styles.value}>{joinDate()}</span>
            </div>

            <div className={styles.box}>
              {t('viewTeamMember.exitDate').toUpperCase()}
              <span className={styles.value}>{exitDate()}</span>
            </div>
          </div>

          <div className="flex flex-column justify-between">
            <div className={styles.box}>
              {t('viewTeamMember.capacity').toUpperCase()}
              <span className={styles.value}>{capacity()}</span>
            </div>

            <div className={styles.box}>
              {t('viewTeamMember.expertise').toUpperCase()}
              <span className={styles.value}>{seniority()}</span>
            </div>
          </div>

          <div className="flex flex-column justify-between">
            <div className={styles.box}>
              {t('viewTeamMember.specialization').toUpperCase()}
              <span className={styles.value}>{specialization()}</span>
            </div>

            <div className={styles.box}>
              {t('viewTeamMember.projectRoles').toUpperCase()}
              <span className={styles.value}>{projectRoles()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
